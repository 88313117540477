<template>
  <div class="base-navigation">
    <div class="container" v-if="navigation.length > 0">
      <router-link
        class="base-navigation-item"
        v-for="(link, index) in navigation"
        :key="`navigation-link-${index}`"
        :to="link.path"
      >
        <inline-svg :class="link.icon" :src="require(`@/assets/icons/${link.icon}.svg`)" />
        {{ link.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "BaseNavigation",
  components: {
    InlineSvg,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.base-navigation {
  background: #ffffff;
  border-bottom: 1px solid #f0f1f4;
  box-sizing: border-box;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.06);

  .container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.base-navigation-item {
  padding: 14px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  transition: color 0.2s ease;

  svg {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    fill: currentColor;
    stroke: currentColor;
  }

  &.router-link-exact-active,
  &:hover {
    color: #0ba3a9;
  }
}
</style>
