import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import Toast, { POSITION } from "vue-toastification";
import router from "./router";
import store from "./store";
import {
  backgroundImageFromStorageDirective,
  imageFromStorageDirective,
} from "@/tools/getImageFromStorage";

// Base CSS
import "@/assets/css/reset.css";
import "@/assets/css/fonts.css";
import "@/assets/css/theme.css";
import "element-plus/lib/theme-chalk/index.css";
import "vue-toastification/dist/index.css";

const toastOptions = {
  position: POSITION.TOP_LEFT,
};

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Toast, toastOptions)
  .directive("bg-image-from-storage", backgroundImageFromStorageDirective)
  .directive("image-from-storage", imageFromStorageDirective)
  .mount("#app");
