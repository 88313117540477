import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "/add-subscriber",
        name: "SubscriberEditor",
        component: () => import("@/views/SubscriberEditor.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: () => import("@/views/Subscriptions.vue"),
  },
  {
    path: "/subscribers",
    name: "Subscribers",
    component: () => import("@/views/Subscribers.vue"),
    children: [
      {
        path: "create-way/",
        name: "SubscriberCreateWay",
        component: () => import("@/views/SubscriberCreateWay.vue"),
      },
      {
        path: "bulk-upload/",
        name: "SubscriberBulkUpload",
        component: () => import("@/views/SubscriberBulkUpload.vue"),
      },
      {
        path: "create/",
        name: "SubscriberCreate",
        component: () => import("@/views/SubscriberEditor.vue"),
      },
      {
        path: "edit/:id",
        name: "SubscriberEdit",
        component: () => import("@/views/SubscriberEditor.vue"),
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-menu-item",
});

export default router;
