import { createStore } from "vuex";
import firebaseInstance from "../config/firebase";
import moment from "moment";
import { subscribeToUpdates } from "./subscribeToUpdates";

const db = firebaseInstance.firestore();

export default createStore({
  state: {
    // Firebase user
    user: {
      loggedIn: false,
      data: null,
    },
    clubId: null, // Club ID based on user's account

    // Club data
    club: {},
    clubSubscriptions: [],

    // Club users
    users: [],
    usersClubSpecific: {},
    usersSubscriptions: {},

    // To refactor?
    applyAction: {
      message: "",
      payment: {
        amount: null,
        message: "",
      },
      changeAmount: {
        amount: null,
        option: null,
      },
      changeActive: {
        isActive: true,
        option: null,
        isTermination: false,
      },
      tags: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    club(state) {
      return state.club;
    },
    clubId(state) {
      return state.club?.id ?? state.clubId ?? null;
    },
    clubSubscriptionById: (state) => (id) => {
      return state.clubSubscriptions?.find((subscription) => subscription?.id === id) ?? null;
    },
    clubSubscriptions(state) {
      return state.clubSubscriptions;
    },
    users(state) {
      return (
        state.users?.map((user) => {
          return {
            ...user,
            subscription: state.usersSubscriptions?.[user.id] ?? null,
            club_specific: state.usersClubSpecific?.[user.id] ?? null,
          };
        }) ?? []
      );
    },
    getUserById: (state, getters) => (id) => {
      return getters.users.find((user) => user.id === id);
    },
  },
  mutations: {
    updateApplyAction(state, payload) {
      state.applyAction[payload.key] = payload.value;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: applyAction`, payload);
    },
    saveClub(state, payload) {
      state.club = payload;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: club`, payload);
    },
    saveClubSubscriptions(state, payload) {
      state.clubSubscriptions = payload;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: clubSubscriptions`, payload);
    },
    saveClubId(state, id) {
      state.clubId = id;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: clubId`, id);
    },
    saveUsers(state, payload) {
      state.users = payload;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: users`, payload);
    },
    saveUser(state, user) {
      state.user = user;
      console.log(`${moment().format("HH:mm:ss")} — Vuex update: user`, user);
    },
    saveUserClubSpecific(state, { data, parameter }) {
      data === null
        ? delete state.usersClubSpecific[parameter]
        : (state.usersClubSpecific[parameter] = data);
    },
    saveUserSubscriptions(state, { data, parameter }) {
      data === null
        ? delete state.usersSubscriptions[parameter]
        : (state.usersSubscriptions[parameter] = data);
    },
  },
  actions: {
    updateApplyAction({ commit }, payload) {
      commit("updateApplyAction", payload);
    },

    /*
    New actions
     */

    // Club
    async bindClub(context) {
      const ref = db.collection("clubs").doc(context.getters.clubId);
      await subscribeToUpdates(ref, "saveClub", context);
    },

    // Club subscriptions
    async bindClubSubscriptions(context) {
      const ref = db.collection("clubs").doc(context.getters.clubId).collection("subscriptions");
      await subscribeToUpdates(ref, "saveClubSubscriptions", context);
    },

    // Club users
    async bindUsers(context) {
      const ref = db.collection("users").where("clubs", "array-contains", context.getters.clubId);
      await subscribeToUpdates(ref, "saveUsers", context);
    },

    // Users Club Specific
    async bindUserClubSpecific(context, userId) {
      const ref = db
        .collection("users")
        .doc(userId)
        .collection("club_specific")
        .doc(context.getters.clubId);

      await subscribeToUpdates(ref, "saveUserClubSpecific", context, userId);
    },
    async bindUsersClubSpecific({ state, dispatch }) {
      state.users.forEach((user) => {
        if (!state.usersClubSpecific?.[user.id]) {
          dispatch("bindUserClubSpecific", user.id);
        }
      });
    },

    // Users Subscriptions
    async bindUserSubscriptions(context, userId) {
      const ref = db
        .collection("users")
        .doc(userId)
        .collection("subscriptions")
        .doc(context.getters.clubId);

      await subscribeToUpdates(ref, "saveUserSubscriptions", context, userId);
    },
    async bindUsersSubscriptions({ state, dispatch }) {
      state.users.forEach((user) => {
        if (!state.usersSubscriptions?.[user.id]) {
          dispatch("bindUserSubscriptions", user.id);
        }
      });
    },
  },
});
