export const subscribeToUpdates = (ref, target, context, targetParameter) => {
  return new Promise((resolve) => {
    ref.onSnapshot(
      async (snapshot) => {
        const isArray = snapshot?.docs;
        let data = isArray ? [] : null;

        if (isArray && snapshot.docs.length > 0) {
          data = snapshot.docs.map((doc) => {
            const docData = doc.data();
            return {
              id: doc.id,
              ...docData,
            };
          });
        } else if (!isArray && snapshot.exists) {
          data = {
            id: snapshot.id,
            ...snapshot.data(),
          };
        }

        targetParameter
          ? await context.commit(target, { data: data, parameter: targetParameter })
          : await context.commit(target, data);
        resolve();
      },
      (error) => {
        console.log(error);
        resolve();
      }
    );
  });
};
