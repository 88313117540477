<template>
  <BaseHeader />
  <BaseNavigation :navigation="navigation" />
  <router-view />
  <div v-loading.fullscreen.lock="isLoading"></div>
</template>

<script>
import firebase from "@/config/firebase";
import BaseHeader from "./components/BaseHeader";
import BaseNavigation from "./components/BaseNavigation";
import { mapGetters, mapState } from "vuex";
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
  name: "App",
  components: {
    BaseNavigation,
    BaseHeader,
  },
  mounted() {
    console.log(`${moment().format("HH:mm:ss")} — App initializes`);

    firebase.auth().onAuthStateChanged(async (user) => {
      this.$store.commit("saveUser", user);

      // Redirect to sign-in on app start
      if (!user) {
        this.$router.push("/sign-in");
        console.log("Not logged in user was redirected.");
      }

      if (!this.redirectLogicInit) {
        this.$router.beforeEach((route) => {
          if (!route.path.includes("sign-in") && !firebase.auth().currentUser) {
            this.$router.push("/sign-in");
            console.log("Not logged in user was redirected.");
          }

          this.redirectLogicInit = true;
        });

        // Show error if we came from Stripe without a success
        if (this.$route.query?.stripeError === true) {
          this.toast.error(
            "There was an error during Stripe Account connection. Please click the button on Clubs page to connect the Stripe account to your club."
          );
        }
      }

      if (user) {
        this.isLoading = true;

        // Find club id
        await firebase
          .firestore()
          .collection("club_admins")
          .where("email", "==", user.email)
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              // Save club id
              const clubId = querySnapshot.docs[0].data().club_id;
              await this.$store.commit("saveClubId", clubId);

              // Load club and users
              await Promise.all([
                this.$store.dispatch("bindClub"),
                this.$store.dispatch("bindClubSubscriptions"),
                this.$store.dispatch("bindUsers"),
              ]);

              this.isLoading = false;
            } else {
              // If no club, log out
              this.toast.error("Club for this user was not found");
              await firebase.auth().signOut();
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.toast.error("Error on loading club data. Please reload the page and try again.");
            console.log("Find club id error: ", error);
            firebase.auth().signOut();
            this.isLoading = false;
          });
      }
    });
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      isLoading: false,
      redirectLogicInit: false,
      navigation: [
        {
          path: "/",
          label: "Home",
          icon: "nav-home",
        },
        {
          path: "/subscribers",
          label: "Subscribers",
          icon: "nav-subscribers",
        },
        {
          path: "/subscriptions",
          label: "Subscriptions",
          icon: "nav-subscriptions",
        },
        /* {
          path: "/reports",
          label: "Reports",
          icon: "nav-reports",
        }, */
        {
          path: "/settings",
          label: "Settings",
          icon: "nav-settings",
        },
      ],
    };
  },
  computed: {
    ...mapState(["usersClubSpecific", "usersSubscriptions"]),
    ...mapGetters(["clubId", "club", "clubSubscriptions", "users"]),
  },
  watch: {
    "$store.state.users": {
      handler(after, before) {
        if (before.length < after.length) {
          // Load from users subCollections
          Promise.all([
            this.$store.dispatch("bindUsersClubSpecific"),
            this.$store.dispatch("bindUsersSubscriptions"),
          ]);
        }
      },
      deep: true,
    },
  },
};
</script>
